
import * as React from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid
} from '@mui/material'
import ChartPie from './components/ChartPie'
import { useDispatch } from 'react-redux'
import { _changePage } from 'pages/home/slices/home.slice'
import ChartRating from './components/ChartRating'
import ChartComplaint from './components/ChartComplaint'
import ChartComments from './components/ChartComments'
import { useQuery } from 'react-query'
import {
  getActiveFTsByDateEndMonths,
  getEvaluationByDateEndMonths
} from 'services/dashboard/DashboardService'
import customerReviewType from 'types/managementControl/customerReview/customerReviewType'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import activeFTsType from 'types/managementControl/customerReview/activeFTsType'
import moment from 'moment'
import Header from './components/Header'

interface IEvaluationData {
  rating: {
    five: number
    four: number
    three: number
    two: number
    one: number
    total: number
    evolution: number
  }
  claims: {
    claims: number
    rates: number
    solutions: number
  }
  comments: customerReviewType[]
}

export default function Dashboard() {
  const dispatch = useDispatch()

  const [date, setDate] = React.useState<Date>(new Date())
  const [evaluationData, setEvaluationData] = React.useState<IEvaluationData>()
  const [activeFTsData, setActiveFTsData] = React.useState<activeFTsType>({
    activeFTs: 0,
    preFTs: 0,
    samples: 0,
    proposals: 0
  })
  const [selectedMonths, setSelectedMonths] = React.useState<number[]>([moment().get('month')])

  React.useEffect(() => {
    dispatch(
      _changePage({
        name: 'Dashboard',
        description: '',
        icon: 'poll',
        route: 'dashboard'
      })
    )
  }, [])

  const { isFetching } = useQuery(
    ['evaluations-months', date],
    async () => {
      return getEvaluationByDateEndMonths(date, selectedMonths)
    },
    {
      onSuccess(data) {
        parseData(data)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  const { isFetching: isFetchingActiveFTs } = useQuery(
    ['active-fts-months', date, selectedMonths],
    async () => {
      return getActiveFTsByDateEndMonths(date, selectedMonths)
    },
    {
      onSuccess(data) {
        setActiveFTsData(data)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  const parseData = (data: customerReviewType[][]) => {
    const fromLastMonth = data[0]
    const fromCurrentMonth = data[1]

    const dataComplete = {
      rating: {
        five: fromCurrentMonth.filter((v) => v.rating === 5).length,
        four: fromCurrentMonth.filter((v) => v.rating === 4).length,
        three: fromCurrentMonth.filter((v) => v.rating === 3).length,
        two: fromCurrentMonth.filter((v) => v.rating === 2).length,
        one: fromCurrentMonth.filter((v) => v.rating === 1).length,
        total: 0,
        evolution: 0
      },
      claims: {
        claims: fromCurrentMonth.filter((v) => v.comment.length > 0)?.length,
        rates: fromCurrentMonth.length,
        solutions: fromCurrentMonth.filter((v) => (v.solution?.length ?? 0) > 0)
          ?.length
      },
      comments: fromCurrentMonth
    }

    let countTotal = 0
    countTotal += dataComplete.rating.five > 0 ? 1 : 0
    countTotal += dataComplete.rating.four > 0 ? 1 : 0
    countTotal += dataComplete.rating.three > 0 ? 1 : 0
    countTotal += dataComplete.rating.two > 0 ? 1 : 0
    countTotal += dataComplete.rating.one > 0 ? 1 : 0

    dataComplete.rating.total =
      (dataComplete.rating.five * 5 +
        dataComplete.rating.four * 4 +
        dataComplete.rating.three * 3 +
        dataComplete.rating.two * 2 +
        dataComplete.rating.one) /
      countTotal

    if (fromLastMonth.length === 0) {
      dataComplete.rating.evolution = 0
    } else {
      const dataCompleteLastMonth = {
        rating: {
          five: fromLastMonth.filter((v) => v.rating === 5).length,
          four: fromLastMonth.filter((v) => v.rating === 4).length,
          three: fromLastMonth.filter((v) => v.rating === 3).length,
          two: fromLastMonth.filter((v) => v.rating === 2).length,
          one: fromLastMonth.filter((v) => v.rating === 1).length,
          total: 0,
          evolution: 0
        },
        claims: {
          claims: fromLastMonth.filter((v) => v.comment.length > 0).length,
          rates: fromLastMonth.length,
          solutions: fromLastMonth.filter((v) => (v.solution?.length ?? 0) > 0)
            .length
        },
        comments: fromLastMonth
      }

      let countTotal = 0
      countTotal += dataCompleteLastMonth.rating.five > 0 ? 1 : 0
      countTotal += dataCompleteLastMonth.rating.four > 0 ? 1 : 0
      countTotal += dataCompleteLastMonth.rating.three > 0 ? 1 : 0
      countTotal += dataCompleteLastMonth.rating.two > 0 ? 1 : 0
      countTotal += dataCompleteLastMonth.rating.one > 0 ? 1 : 0

      dataCompleteLastMonth.rating.total =
        (dataCompleteLastMonth.rating.five * 5 +
          dataCompleteLastMonth.rating.four * 4 +
          dataCompleteLastMonth.rating.three * 3 +
          dataCompleteLastMonth.rating.two * 2 +
          dataCompleteLastMonth.rating.one) /
        countTotal

      const lastMonthPercent =
        (Math.round(dataCompleteLastMonth.rating.total) * 100) / 5
      const currentMonthPercent =
        (Math.round(dataComplete.rating.total) * 100) / 5
      dataComplete.rating.evolution = currentMonthPercent - lastMonthPercent
    }

    setEvaluationData(dataComplete)
  }

  return (
    <Can module={MODULES.DASHBOARD} permissions={[PERMISSIONS.READ]}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CssBaseline />
        <Header
          dateSearch={(date) => {
            setDate(date)
          }}
          months={(values) => setSelectedMonths(values)}
        />
        {isFetching || isFetchingActiveFTs
? (
          <Box
            sx={{ display: 'flex' }}
            style={{
              justifyContent: 'center',
              marginTop: '124px',
              marginRight: '88px'
            }}
          >
            <CircularProgress />
          </Box>
        )
: (
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <ChartPie
                      month={moment(date).format('MMMM')}
                      title={'Solicitações X FTs Ativas'}
                      data={[
                        {
                          title: 'Solicitações',
                          value: activeFTsData.preFTs,
                          fill: '#4060E6'
                        },
                        {
                          title: 'FTs Ativas',
                          value: activeFTsData.activeFTs,
                          fill: '#1AB1B0'
                        }
                      ]}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <ChartPie
                      month={moment(date).format('MMMM')}
                      title={'Amostras enviadas X FTs Ativas'}
                      data={[
                        {
                          title: 'Amostras',
                          value: activeFTsData.samples,
                          fill: '#4060E6'
                        },
                        {
                          title: 'FTs Ativas',
                          value: activeFTsData.activeFTs,
                          fill: '#1AB1B0'
                        }
                      ]}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <ChartPie
                      month={moment(date).format('MMMM')}
                      title={'Propostas X FTs Ativas'}
                      data={[
                        {
                          title: 'Propostas',
                          value: activeFTsData.proposals,
                          fill: '#4060E6'
                        },
                        {
                          title: 'FTs Ativas',
                          value: activeFTsData.activeFTs,
                          fill: '#1AB1B0'
                        }
                      ]}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={5}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <ChartRating
                      total={evaluationData?.rating?.total}
                      evolution={evaluationData?.rating?.evolution}
                      title={'Avaliação de clientes'}
                      data={[
                        {
                          title: '+5',
                          value: evaluationData?.rating.five,
                          color: '#19B5B4'
                        },
                        {
                          title: '+4',
                          value: evaluationData?.rating.four,
                          color: '#4060E6'
                        },
                        {
                          title: '+3',
                          value: evaluationData?.rating.three,
                          color: '#EEBD20'
                        },
                        {
                          title: '+2',
                          value: evaluationData?.rating.two,
                          color: '#EE207A'
                        },
                        {
                          title: '+1',
                          value: evaluationData?.rating.one,
                          color: '#F5222D'
                        }
                      ]}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <ChartComplaint
                      title={'Reclamações'}
                      data={[
                        {
                          title: 'Reclamações',
                          value: evaluationData?.claims.claims,
                          color: '#4060E6'
                        },
                        {
                          title: 'Avaliações',
                          value: evaluationData?.claims.rates,
                          color: '#EE207A'
                        },
                        {
                          title: 'Solucionados',
                          value: evaluationData?.claims.solutions,
                          color: '#EEBD20'
                        }
                      ]}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <ChartComments
                      title={'Comentários'}
                      comments={evaluationData?.comments}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    </Can>
  )
}
