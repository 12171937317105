import React, { useCallback, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, IconButton, Menu, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import PrintIcon from '@mui/icons-material/Print'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Search } from '@mui/icons-material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { GetTagProductList, printTag } from 'services/productControl/ProductControlService'
import { toast } from 'react-toastify'

interface TagProduct {
  id: number
  produto: string
  cliente: string
  ficha_tecnica: string
  qtd_produto: string
  data_transacao: string
  armazem: string
}

const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout
  return (...args: any[]) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

const Tagging: React.FC = () => {
  const [rows, setRows] = useState([] as TagProduct[])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleNavigate = useCallback(async (path: string) => {
    try {
      navigate(path)
    } catch (error) {
      console.error('Navigation error:', error)
    }
  }, [navigate])

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchValue = e.target.value
    setLoading(true)
    setRows([])
    const response = await GetTagProductList(searchValue)
    setRows(response.data)
    setLoading(false)
  }

  const sendPrintLabel = async (tagData: { id: string, produto: string, cliente: string, qtd_produto: number, data_transacao: string, armazem: string }) => {
    try {
      setLoading(true)
      const zpl = `
  ^XA
  ^CF0,60
  ^FO80,50^FDPCE.^FS
  ^CF0,30
  ^FO50,250^GB700,3,3^FS
  ^FX Segunda seção com informações do destinatário e do produto.
  ^CFA,30
  ^FO50,300^FDCLIENTE:^FS
  ^FO50,340^FD${tagData.cliente}^FS
  ^FO50,380^FDPRODUTO:^FS
  ^FO50,420^FD${tagData.produto}^FS
  ^FO50,460^FDARMAZEM:^FS
  ^FO50,500^FD${tagData.armazem}^FS
  ^FO50,540^FDDATA TRANSACAO:^FS
  ^FO50,580^FD${tagData.data_transacao}^FS
  ^FO50,620^FDQUANTIDADE:^FS
  ^FO50,660^FD${tagData.qtd_produto}^FS
  ^CFA,15
  ^FO50,710^GB700,3,3^FS
  ^FX Terceira seção com código de barras.
  ^BY2,2,170
  ^FO30,750^BC^FDFD${tagData.produto}^FS
  ^XZ
  `
      // Aqui você faria a chamada para a API ou serviço para enviar o ZPL para a impressora.
      const response = await printTag(zpl)
      console.log('Tag printed successfully:', response)
      toast.success('Tag impressa com sucesso!')
      setLoading(false)
    } catch (error) {
      toast.error('Erro ao imprimir a etiqueta.')
      console.error('Error sending print tag:', error)
      setLoading(false)
    }
  }
  const sendPrintTag = async (tagData: any) => {
    const data = {
      armazem: tagData.armazem,
      cliente: tagData.cliente,
      data_transacao: tagData.data_transacao,
      id: tagData.id,
      item: tagData.produto,
      qtd_produto: tagData.qtd_produto
    }

    try {
      setLoading(true)
      const zpl = `
        ^XA
^CF0,60
^FO80,50^FDPCE.^FS
^CF0,30
^FO50,150^GB700,3,3^FS
^FX Segunda seção com informações do destinatário e do produto.
^CFA,30
^FO50,200^FDCLIENTE:^FS
^FO50,240^FD${data.cliente}^FS
^FO50,280^FDPRODUTO:^FS
^FO50,320^FD${data.item}^FS
^FO50,360^FDARMAZEM:^FS
^FO50,400^FD${data.armazem}^FS
^FO50,440^FDDATA TRANSACAO:^FS
^FO50,480^FD${data.data_transacao}^FS
^FO50,520^FDQUANTIDADE:^FS
^FO50,560^FD${data.qtd_produto}^FS
^CFA,15
^FO50,610^GB700,3,3^FS
^FX Terceira seção com QR Code.
^BQ,2,10
^FO60,620^FDMA,{ "armazem": "${data.armazem}", "cliente": "${data.cliente}", "data_transacao": "${data.data_transacao}", "id": "${data.id}", "item": "${data.item}", "qtd_produto": ${data.qtd_produto} }^FS
^XZ

      `
      // Aqui você faria a chamada para a API ou serviço para enviar o ZPL para a impressora.
      const response = await printTag(zpl)
      console.log('Tag impressa com sucesso:', response)
      toast.success('Tag impressa com sucesso!')
      setLoading(false)
    } catch (error) {
      console.error('Erro ao enviar a tag para impressão:', error)
      toast.error('Erro ao imprimir a tag.')
      setLoading(false)
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const debouncedHandleSearchChange = useCallback(debounce(handleSearchChange, 500), [])

  useEffect(() => {
    const fetchData = async (search: string = '') => {
      try {
        setLoading(true)
        const response = await GetTagProductList(search)
        setRows(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (id: number) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const columns: GridColDef[] = [
    { field: 'produto', headerName: 'Produto', width: 320 },
    { field: 'cliente', headerName: 'cliente', width: 320 },
    { field: 'qtd_produto', headerName: 'Qtd Produto', width: 110 },
    { field: 'data_transacao', headerName: 'Data Transação', width: 130 },
    { field: 'armazem', headerName: 'Armazém', width: 110 },
    {
      field: 'Actions',
      headerName: 'Ações',
      width: 300,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'space-around', gap: '10px' }}>
          {params.row.loading
            ? (
              <Typography variant="body2">Loading...</Typography>
            )
            : (
              <>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  size="small"
                  onClick={async () => {
                    const updatedRows = rows.map(row => row.id === params.row.id ? { ...row, loading: true } : row)
                    setRows(updatedRows)
                    await sendPrintLabel(params.row)
                    const updatedRowsAfter = rows.map(row => row.id === params.row.id ? { ...row, loading: false } : row)
                    setRows(updatedRowsAfter)
                  }}
                  style={{ minWidth: '0', minHeight: '20px', backgroundColor: '#4060E6', color: '#ffffff', marginTop: '10px' }}
                  disableElevation
                >
                  <PrintIcon style={{ fontSize: '26px' }} />
                  Etiqueta
                </Button>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  size="small"
                  onClick={async () => {
                    const updatedRows = rows.map(row => row.id === params.row.id ? { ...row, loading: true } : row)
                    setRows(updatedRows)
                    await sendPrintTag(params.row)
                    const updatedRowsAfter = rows.map(row => row.id === params.row.id ? { ...row, loading: false } : row)
                    setRows(updatedRowsAfter)
                  }}
                  style={{ minWidth: '0', minHeight: '20px', backgroundColor: '#4060E6', color: '#ffffff', marginTop: '10px' }}
                  disableElevation
                >
                  <PrintIcon style={{ fontSize: '26px' }} />
                  TAG
                </Button>
              </>
            )}
        </div>
      )
    }
  ]

  const expeditionItens = [
    {
      client: 'SAMSUNG ELTR-00880',
      description: 'CHAPA DE PAP OND.',
      saldo: '100.000',
      qtdRequest: '200',
      items: [
        { id: 1, palet: '1/20', qtd: '150', address: 'h1/b1/fg', input: '10/04/05', status: 'retirar' },
        { id: 1, palet: '1/20', qtd: '150', address: 'h1/b1/fg', input: '10/04/05', status: 'fragmentar' }
      ]
    }
  ]

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton
          size='small'
          style={{ backgroundColor: '#4060E6', borderRadius: '0', color: '#ffffff' }}
          onClick={async () => handleNavigate('/home/stock-create')}
        >
          <AddIcon />
        </IconButton>
      </div>
      <div>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="stock tabs">
          <Tab label="Produção" />
          <Tab label="Expedição" />
        </Tabs>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'space-between' }}>
          <TextField
            label="Buscar"
            variant="standard"
            size="small"
            sx={{ mr: 2 }}
            InputProps={{
              endAdornment: (
                <IconButton size="small">
                  <Search />
                </IconButton>
              )
            }}
            onChange={debouncedHandleSearchChange}
          />
          <Button
            onClick={async () => handleNavigate('/home/tagging-queue')}
            variant="outlined"
            style={{ color: '#A90000', borderColor: '#A90000' }}
            endIcon={<ArrowRightAltIcon />}
            startIcon={
              <span
                style={{
                  color: 'white',
                  backgroundColor: '#A90000',
                  fontSize: '10px',
                  width: '18px',
                  borderRadius: '55%'
                }}>2</span>
            }
          >
            produtos em fila de espera
          </Button>
        </Box>
        {
          tabValue === 0 && (
            <Typography component="div">
              <DataGrid columns={columns} pageSize={15} rowsPerPageOptions={[15]} autoHeight rows={rows} loading={loading} />
            </Typography>
          )
        }
        {tabValue === 1 && (
          <Box>
            {expeditionItens.map((item, index) => (
              <Accordion key={index} elevation={1}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                      <Typography variant='subtitle2'>Cliente</Typography>
                      <Typography>{item.client}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2'>Descrição</Typography>
                      <Typography>{item.description}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2'>Quantidade - Saldo</Typography>
                      <Typography>{item.saldo}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2'>Quantidade solicitada</Typography>
                      <Typography>{item.qtdRequest}</Typography>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#F5F5F5', padding: '0' }}>
                  {item.items.map((subItem) => (
                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '15px', border: '1px solid #E2E2E2' }} key={subItem.id}>
                      <div>
                        <Typography variant='subtitle2'>Palete:</Typography>
                        <Typography variant='subtitle2'>{subItem.palet}</Typography>
                      </div>
                      <div>
                        <Typography variant='subtitle2'>Quantidade:</Typography>
                        <Typography variant='subtitle2'>{subItem.qtd}</Typography>
                      </div>
                      <div>
                        <Typography variant='subtitle2'>Endereço:</Typography>
                        <Typography variant='subtitle2'>{subItem.address}</Typography>
                      </div>
                      <div>
                        <Typography variant='subtitle2'>Entrada:</Typography>
                        <Typography variant='subtitle2'>{subItem.input}</Typography>
                      </div>
                      <div>
                        <Typography variant='subtitle2'>Status:</Typography>
                        <Typography variant='subtitle2'>
                          <Chip
                            label={subItem.status}
                            style={{
                              borderColor: subItem.status === 'retirar' ? '#4F8FE3' : '#FEBD38',
                              color: subItem.status === 'retirar' ? '#4F8FE3' : '#FEBD38',
                              borderRadius: '5px',
                              width: '90px',
                              textTransform: 'capitalize'
                            }}
                            variant='outlined'
                          />
                        </Typography>
                      </div>
                      <div>
                        <Button
                          id="demo-positioned-button"
                          aria-controls={open ? 'demo-positioned-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          size="small"
                          onClick={handleClick(subItem.id)}
                          style={{ minWidth: '0', minHeight: '20px', backgroundColor: '#4060E6', color: '#ffffff', marginTop: '10px' }}
                          disableElevation
                        >
                          <AddIcon style={{ fontSize: '26px' }} />
                        </Button>
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                        >
                          <MenuItem onClick={handleClose}>Imprimir TAG</MenuItem>
                          <MenuItem onClick={handleClose}>Imprimir Etiqueta</MenuItem>
                        </Menu>
                      </div>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}
      </div>
    </Box>
  )
}

export default React.memo(Tagging)
