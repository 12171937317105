import { httpClient, apiTrimbox } from 'config/httpClient'
import moment from 'moment'
import activeFTsType from 'types/managementControl/customerReview/activeFTsType'
import customerReviewType from 'types/managementControl/customerReview/customerReviewType'

const encrypt = (text: string) => {
  const encrypted: string = window.btoa(apiTrimbox + text)
  return encrypted.replaceAll('/', '---')
}

export const getEvaluationByDate = async (
  date: Date
): Promise<customerReviewType[][]> => {
  const { data } = await httpClient.get(
    `/evaluations/findByDate?month=${moment(date).format('MM')}&year=${moment(
      date
    ).format('YYYY')}&lastMonth=${moment(date).add(-1, 'M').format('MM')}`
  )
  return data as customerReviewType[][]
}

export const getActiveFTsByDate = async (date): Promise<activeFTsType> => {
  const urlTrimbox = encrypt(
    `ft/activeFTs?startDate=${
      moment(date).format('YYYY-MM-') + '01T00:00:00'
    }&endDate=${
      moment(date).format('YYYY-MM-') +
      moment(date).endOf('month').format('DD') +
      'T00:00:00'
    }`
  )
  const { data } = await httpClient.get(
    `/evaluations/findActiveFTsByDate?month=${moment(date).format(
      'MM'
    )}&year=${moment(date).format('YYYY')}&urlEncodedTrimbox=${urlTrimbox}`
  )
  return data as activeFTsType
}

export const getEvaluationByDateEndMonths = async (
  date: Date,
  months: number[]
): Promise<customerReviewType[][]> => {
  if (!months.length) return []

  const results: customerReviewType[][] = []

  for (const month of months) {
    const { data } = await httpClient.get(
      `/evaluations/findByDate?month=${(month + 1)
        .toString()
        .padStart(2, '0')}&year=${moment(date).format(
        'YYYY'
      )}&lastMonth=${(month === 0 ? 12 : month).toString().padStart(2, '0')}`
    )

    results.push(...(data as customerReviewType[][]))
  }

  return results
}

export const getActiveFTsByDateEndMonths = async (
  date,
  months: number[]
): Promise<activeFTsType> => {
  if (!months.length) {
    return {
      activeFTs: 0,
      preFTs: 0,
      proposals: 0,
      samples: 0
    }
  }

  const results: activeFTsType = {
    activeFTs: 0,
    preFTs: 0,
    proposals: 0,
    samples: 0
  }

  for (const month of months) {
    const urlTrimbox = encrypt(
      `ft/activeFTs?startDate=${
        moment(date).format('YYYY-') +
        (month + 1).toString().padStart(2, '0') +
        '-' +
        '01T00:00:00'
      }&endDate=${
        moment(date).format('YYYY-') +
        (month + 1).toString().padStart(2, '0') +
        '-' +
        moment(date).set('month', month).endOf('month').format('DD') +
        'T00:00:00'
      }`
    )
    const { data } = await httpClient.get(
      `/evaluations/findActiveFTsByDate?month=${(month + 1)
        .toString()
        .padStart(2, '0')}&year=${moment(date).format(
        'YYYY'
      )}&urlEncodedTrimbox=${urlTrimbox}`
    )
    const { activeFTs, preFTs, proposals, samples } = data as activeFTsType
    results.activeFTs = results.activeFTs + activeFTs
    results.preFTs = results.preFTs + preFTs
    results.proposals = results.proposals + proposals
    results.samples = results.samples + samples
  }

  return results
}
