import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

interface DashboardDataProps {
  clientInStock: Array<{
    client: string
    quantity: number
  }>
  weekEntries: {
    friday: number
    monday: number
    saturday: number
    sunday: number
    thursday: number
    tuesday: number
    wednesday: number
  }
  weekOutputs: {
    friday: number
    monday: number
    saturday: number
    sunday: number
    thursday: number
    tuesday: number
    wednesday: number
  }
}

interface CustomBarChartProps {
  dashboardData: DashboardDataProps
}

const CustomBarChart: React.FC<CustomBarChartProps> = ({ dashboardData }) => {
  const data = Object.keys(dashboardData.weekEntries).map(day => ({
    day,
    entradas: dashboardData.weekEntries[day as keyof typeof dashboardData.weekEntries],
    saidas: dashboardData.weekOutputs[day as keyof typeof dashboardData.weekOutputs]
  }))

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <defs>
          <linearGradient id="colorEntrada" x1="0" y1="0" x2="0" y2="1">
            <stop offset="1%" stopColor="#ffd572" stopOpacity={1} />
            <stop offset="99%" stopColor="#febd38" stopOpacity={1} />
          </linearGradient>
          <linearGradient id="colorSaida" x1="0" y1="0" x2="0" y2="1">
            <stop offset="1%" stopColor="#b09fff" stopOpacity={1} />
            <stop offset="99%" stopColor="#8d79f6" stopOpacity={1} />
          </linearGradient>
        </defs>

        <XAxis dataKey="day" tick={{ fontSize: 10 }} />
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: 10, fontWeight: 'bold' }} />

        <Bar
          dataKey="entradas"
          fill="url(#colorEntrada)"
          radius={[0, 0, 0, 0]}
          background={{ fill: '#A08CFB20', radius: [10, 10, 0, 0] }}
          barSize={50}
          stackId={1}
        />
        <Bar
          dataKey="saidas"
          fill="url(#colorSaida)"
          radius={[10, 10, 0, 0]}
          background={{ fill: '#A08CFB20', radius: [10, 10, 0, 0] }}
          barSize={50}
          stackId={1}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default CustomBarChart
