import React, { useState, useEffect } from 'react'
import { Modal, Box, Button, Typography, Grid, TextField } from '@mui/material'
import { Add } from '@mui/icons-material'

interface AddStationModalProps {
  streetIndex: number
  streetName: string
  side: number
  onSave: (workstation: any) => void
}

const AddStationModal: React.FC<AddStationModalProps> = (props) => {
  const { streetName, onSave } = props
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [workstation, setWorkstation] = React.useState({
    side: props.side,
    name: '',
    streetName: props.streetName,
    streetIndex: props.streetIndex,
    qtdShelves: 1,
    shelves: Array.from({ length: 1 }, () => ({ positions: 0 }))
  })

  useEffect(() => {
    setWorkstation((prev) => ({
      ...prev,
      shelves: Array.from({ length: Math.min(prev.qtdShelves, 4) }, () => ({ positions: 0 }))
    }))
  }, [workstation.qtdShelves])

  const handleSave = () => {
    onSave(workstation)
    handleClose()
  }

  return (
    <div style={{ marginTop: '1rem' }}>
      <Button variant="outlined" color="primary" onClick={handleOpen} endIcon={<Add />}>
        Adicionar estação
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Adicionar Estação
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                id="outlined-required"
                label="Nome"
                defaultValue=""
                variant='standard'
                onChange={(e) => setWorkstation({ ...workstation, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-required"
                label="Rua"
                defaultValue={streetName}
                variant='standard'
                disabled
                onChange={(e) => setWorkstation({ ...workstation, streetName: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="outlined-required"
                label="Prateleiras"
                variant='standard'
                defaultValue={1}
                onChange={(e) => setWorkstation({ ...workstation, qtdShelves: Number(e.target.value) })}
              />
            </Grid>
            <Grid container spacing={2} item xs={12}>
              {Array.from({ length: Math.min(workstation.qtdShelves, 4) }).map((_, index) => (
                <Grid item xs={6} key={index}>
                  <TextField
                    required
                    id={`outlined-required-${index}`}
                    label={`Qtde. de posições prateleira ${index + 1}`}
                    defaultValue=""
                    placeholder='Ex: 123456'
                    variant='standard'
                    fullWidth
                    onChange={(e) => {
                      const newShelves = workstation.shelves.map((shelf, i) => {
                        if (i === index) {
                          return { positions: Number(e.target.value) }
                        }
                        return shelf
                      })
                      setWorkstation({ ...workstation, shelves: newShelves })
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
            <Button onClick={() => {
              handleClose()
              setWorkstation({
                side: props.side,
                name: '',
                streetName: '',
                streetIndex: props.streetIndex,
                qtdShelves: 1,
                shelves: Array.from({ length: 1 }, () => ({ positions: 0 }))
              })
            }} variant="outlined" color="error" sx={{ mt: 2 }}>
              Cancelar
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2 }} disableElevation>
              salvar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>

  )
}

export default AddStationModal
