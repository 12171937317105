import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { experimentalStyled as styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import CircularProgress from '@mui/material/CircularProgress'
import PieChart from './components/PieChart'
import BarChart from './components/BarChart'
import { useNavigate } from 'react-router-dom'
import { GetDashboardDatas } from 'services/productControl/ProductControlService'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderLeft: '10px solid #4060E6',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f4f4f4'
  },
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.2rem',
  cursor: 'pointer'
}))

const GraphContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontSize: '1.2rem'
}))

interface DashboardData {
  clientInStock: Array<{
    client: string
    quantity: number
  }>
  weekEntries: {
    friday: number
    monday: number
    saturday: number
    sunday: number
    thursday: number
    tuesday: number
    wednesday: number
  }
  weekOutputs: {
    friday: number
    monday: number
    saturday: number
    sunday: number
    thursday: number
    tuesday: number
    wednesday: number
  }
}

const ProductControl: React.FC = () => {
  const navigate = useNavigate()
  const handleNavigate = async (path: string) => {
    try {
      navigate(path)
    } catch (error) {
      console.error('Navigation error:', error)
    }
  }

  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await GetDashboardDatas()
        setDashboardData(response.data)
      } catch (err) {
        console.error('Erro ao buscar os dados do dashboard:', err)
        setError('Não foi possível carregar os dados do gráfico.')
      } finally {
        setLoading(false)
      }
    }
    fetchDashboardData()
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 1 }} columns={{ xs: 4, sm: 8, md: 9 }} style={{ marginTop: 12 }}>
        <Grid item xs={2} sm={4} md={3}>
          <Item onClick={async () => handleNavigate('/home/stock-list')}>
            <Icon style={{ marginRight: '40px', color: '#4060E6' }}>visibility</Icon>
            Visualizar Estoque
          </Item>
        </Grid>
        <Grid item xs={2} sm={4} md={3}>
          <Item onClick={async () => handleNavigate('/home/stock-create')}>
            <Icon style={{ marginRight: '40px', color: '#4060E6' }}>create_new_folder</Icon>
            Criar Estoque
          </Item>
        </Grid>
        <Grid item xs={2} sm={4} md={3}>
          <Item onClick={async () => handleNavigate('/home/tagging')}>
            <Icon style={{ marginRight: '40px', color: '#4060E6' }}>label_important</Icon>
            Etiquetagem
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 6, md: 6 }} style={{ marginTop: 12 }}>
        <Grid item xs={12} sm={12} md={3} style={{ fontSize: '1.2rem' }}>
          Produtos de clientes no estoque
          <GraphContainer>
            {loading ? <CircularProgress /> : dashboardData && <PieChart dashboardData={dashboardData} />}
          </GraphContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={3} style={{ fontSize: '1.2rem' }}>
          Entrada e saída de produtos
          <GraphContainer>
            {loading ? <CircularProgress /> : dashboardData && <BarChart dashboardData={dashboardData} />}
          </GraphContainer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProductControl
