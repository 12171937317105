import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, Typography } from '@mui/material'
import Chip from '@mui/material/Chip'

interface StockCardProps {
  stockData: {
    id: string
    address: string
    responsible: string
    stockName: string
    streetsNumber: string
    uniqueCode: string
  }
}

const CardStock: React.FC<StockCardProps> = ({ stockData }) => {
  const navigate = useNavigate()

  const handleNavigate = useCallback(async (path: string) => {
    try {
      navigate(path)
    } catch (error) {
      console.error('Navigation error:', error)
    }
  }, [navigate])

  return (
    <Card elevation={5} style={{ borderLeft: '8px solid #17FFC9', borderRight: '8px solid #17FFC9', borderRadius: '5px', margin: '10px', cursor: 'pointer' }}
      onClick={async () => handleNavigate(`/home/stock-view/${stockData.id}`)}>
      <CardContent>
      <Typography variant="body1" component="div">
        {stockData.stockName}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {stockData.address}
      </Typography>
      <div style={{ display: 'flex' }}>
        <Typography variant="body2" color="text.primary" style={{ marginRight: '4px' }}>Responsável:</Typography>
        <Typography variant="body2" color="text.secondary">
        {stockData.responsible}
        </Typography>
      </div>
      <div style={{ display: 'flex', gap: '2px' }}>
        <Typography variant="body2" color="text.secondary">
        Capacidade: {stockData.streetsNumber} ruas
        </Typography>
      </div>
      <Chip label={'código: ' + stockData.uniqueCode} variant="outlined" size="small" />
      </CardContent>
    </Card>
  )
}

export default CardStock
