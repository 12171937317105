import React, { useEffect, useCallback, useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { CircularProgress, IconButton, Typography } from '@mui/material'
import CardStock from './components/CardStock'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import { getStockAll } from 'services/productControl/ProductControlService'

interface Stock {
  id: string
  address: string
  responsible: string
  stockName: string
  streetsNumber: string
  uniqueCode: string
}

const StockList: React.FC = () => {
  const [dataStock, setDataStock] = React.useState<Stock[]>([])
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate()

  const getStocks = useCallback(async () => {
    try {
      const response = await getStockAll()
      const uniqueStocks = response.data.filter((stock: Stock) =>
        !dataStock.some(existingStock => existingStock.id === stock.id)
      )
      setDataStock(prevDataStock => [...prevDataStock, ...uniqueStocks])
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }, [dataStock])

  useEffect(() => {
    getStocks()
  }, [])

  const handleNavigate = useCallback(async (path: string) => {
    try {
      navigate(path)
    } catch (error) {
      console.error('Navigation error:', error)
    }
  }, [navigate])

  const renderStocks = useMemo(() => {
    return dataStock.map((stock) => (
      <Grid item key={stock.id} xs={4}>
        <CardStock stockData={stock} />
      </Grid>
    ))
  }, [dataStock])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IconButton
        size='small'
        style={{ backgroundColor: '#4060E6', borderRadius: '0', color: '#ffffff' }}
        onClick={async () => handleNavigate('/home/stock-create')}
      >
        <AddIcon />
      </IconButton>
      {loading
? (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress size={100} />
        </Box>
      )
: (
        dataStock.length === 0
? (
          <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
            <Typography variant="h6">não há estoque cadastrado</Typography>
          </Box>
        )
: (
          <Grid container rowGap={1} marginTop={2}>
            {renderStocks}
          </Grid>
        )
      )}
    </Box>
  )
}

export default React.memo(StockList)
