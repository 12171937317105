import React from 'react'
import { Chip } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

const TaggingQueue: React.FC = () => {
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nome', width: 120 },
    { field: 'code', headerName: 'código', width: 70 },
    { field: 'client', headerName: 'Cliente', width: 70 },
    { field: 'street', headerName: 'Rua', width: 60 },
    { field: 'side', headerName: 'Lado', width: 70 },
    { field: 'station', headerName: 'Estação/Torre', width: 120 },
    { field: 'shelf', headerName: 'Prateleita', width: 90 },
    { field: 'position', headerName: 'Posição', width: 80 },
    { field: 'status', headerName: 'status', width: 50 },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      align: 'center',
      renderCell: (params) => {
        let color
        switch (params.value) {
          case 'liberado':
            color = '#36BE4C'
            break
          case 'em espera':
            color = '#C98907'
            break
          default:
            color = 'grey'
        }
        return (
          <Chip
            label={params.value}
            style={{
              borderColor: color,
              color: color,
              borderRadius: '5px',
              textTransform: 'capitalize',
              width: '90px'
             }}
            variant='outlined'
          />
        )
      }
    }
  ]

  const rows = [
    { id: 1, name: 'Coca-cola', code: '123', client: 'Coca-cola', street: '123', side: 'AB', station: '223', shelf: '123', position: 'A', status: 'liberado' },
    { id: 2, name: 'PepSi', code: '123', client: 'PepSi', street: '121', side: 'AB', station: '53', shelf: '123', position: 'B', status: 'em espera' }
  ]

  return (
    <Box sx={{ flexGrow: 1, marginTop: '20px' }}>
      <DataGrid columns={columns} pageSize={5} rowsPerPageOptions={[5]} autoHeight rows={rows} />
    </Box>
  )
}

export default React.memo(TaggingQueue)
