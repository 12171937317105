import React from 'react'
import ReactDOM from 'react-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import App from './App'
// import { ReactQueryDevtools } from 'react-query/devtools'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Provider } from 'react-redux'
import { persistor, store } from './core/store'
import { PersistGate } from 'redux-persist/es/integration/react'

import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const theme = createTheme({
  palette: {
    primary: {
      main: '#4060e6'
    },
    error: {
      main: '#EE207A'
    },
    success: {
      main: '#36BE4C'
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
