import { Box } from '@mui/material'
import { Can } from 'components/Permission/Can'
import CardView from 'components/view/CardView'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import React from 'react'

const managementControl: React.FC = () => {
  return (
    <Can
      module={MODULES.GERENCIAL}
      permissions={[PERMISSIONS.READ]}
    >
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <h4>Gerencial</h4>
        <CardView title='Avaliações do App Cliente' rote='/home/managementControl/management/customer-review' />
        <CardView title='Estoque' rote='/home/managementControl/estoque' />
        <CardView title='Planejado dia' rote='/home/managementControl/rh' />
        <CardView title='Previsão' rote='/home/managementControl/management' />
        <CardView title='Propostas para a Controladoria' rote='/home/report/proposalsForDirection' />
        <CardView title='Solicitações de propostas' rote='/home/report/requestForProposals' />
        <CardView title='Valores de materiais' rote='/home/managementControl/management/material-taxes' />
        {/* <CardView title='Meta de produção' rote='/home/managementControl/production-goals' /> */}
        {/* <CardView title='Meta de venda' rote='/home/managementControl/management/sales-goals' /> */}
        {/* <CardView title='Meta de vendedor' rote='/home/managementControl/management/proposal-goals' /> */}
      </Box>
    </>
    </Can>
  )
}

export default managementControl
