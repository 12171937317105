import { PageDetails } from 'pages/home/models/PageDetails'

export const modulesBase: PageDetails[] = [
  {
    name: 'Dashboard',
    description: '',
    icon: 'poll',
    route: 'dashboard',
    cod: 'MD'
  },
  {
    name: 'Perfil',
    description:
      'Controle de Perfil Registre o perfil e controle o acesso aos módulos do sistema',
    icon: 'account_box',
    route: 'profile-control',
    cod: 'MP'
  },
  {
    name: 'Notificações',
    description: 'Gerencie as notificações do sistema',
    icon: 'comment',
    route: 'notifications',
    cod: ''
  },
  {
    name: 'Usuários',
    description: 'Controle de Usuários Registre usuários e crie novas senhas',
    icon: 'group_add',
    route: 'registration',
    cod: 'MU'
  },
  {
    name: 'Clientes',
    description: 'Registre e gerencie seus clientes',
    icon: 'perm_contact_calendar',
    route: 'customer-control',
    cod: 'MC'
  },
  {
    name: 'Empresa',
    description: 'Registre e gerencie as informações sobre sua empresa',
    icon: 'business',
    route: 'company',
    cod: 'ME'
  },
  {
    name: 'Alertas',
    description: 'Gerencie alertas sobre atividades de usuários e do sistema',
    icon: 'warning',
    route: 'alerts',
    cod: 'MA'
  },
  {
    name: 'Gerencial',
    description: 'Gerencie o controle dos relatórios do sistema',
    icon: 'manage_accounts',
    route: 'managementControl',
    cod: 'MG'
  },
  // {
  //   name: 'Projetos',
  //   description: '',
  //   icon: 'inventory_2',
  //   route: 'projects',
  // cod: ''
  // },
  // {
  //   name: 'Solicitação de compras',
  //   description: '',
  //   icon: 'shopping_bag',
  //   route: 'purchases',
  // cod: ''
  // },
  {
    name: 'Pedido de Venda',
    description: "Gerencie e receba pedidos de compras de suas FT's",
    icon: 'shopping_bag',
    route: 'purchaseRequests',
    cod: 'MO'
  },
  {
    name: 'FT',
    description: "Faça a inspeção de suas FT' e mande para o setor de compras",
    icon: 'description',
    route: 'ft',
    cod: 'MT'
  },
  {
    name: 'Relatórios',
    description: 'Gerencie os relatórios do sistema',
    icon: 'summarize',
    route: 'report',
    cod: 'MR'
  },
  {
    name: 'Ordens Produção',
    description: 'Gerenciar Ordens de Produção',
    icon: 'filter_frames',
    route: 'ordersProduction',
    cod: 'OP'
  },
  {
    name: 'Controle de Produtos',
    description: 'Gerenciar produtos',
    icon: 'storage',
    route: 'product-control',
    cod: 'CP'
  }
  // {
  //   name: 'Solicitações Clientes',
  //   description: 'Gerenciar solicitações de clientes',
  //   icon: 'description',
  //   route: 'solicitations',
  //   cod: 'MT'
  // }
  // {
  //   name: 'Chat',
  //   description: '',
  //   icon: 'chat',
  //   route: 'chat',
  // cod: ''
  // }
]

export const modules: PageDetails[] = [
  ...modulesBase,
  {
    name: 'Propostas Controladoria',
    description: '',
    icon: '',
    route: 'report/proposalsForDirection',
    cod: 'PD'
  },
  {
    name: 'Calculadora',
    description: '',
    icon: '',
    route: 'calculator',
    cod: 'MT'
  }
]
