import React from 'react'
import { PieChart, Pie, Cell, Legend } from 'recharts'

interface DashboardData {
  clientInStock: Array<{
    client: string
    quantity: number
  }>
  weekEntries: {
    friday: number
    monday: number
    saturday: number
    sunday: number
    thursday: number
    tuesday: number
    wednesday: number
  }
  weekOutputs: {
    friday: number
    monday: number
    saturday: number
    sunday: number
    thursday: number
    tuesday: number
    wednesday: number
  }
}

interface CustomPieChartProps {
  dashboardData: DashboardData
}

const COLORS = [
  '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28FD0', '#FF6666', '#FFB6C1', '#8A2BE2', '#5F9EA0', '#7FFF00',
  '#D2691E', '#FF7F50', '#6495ED', '#DC143C', '#00FFFF', '#00008B', '#008B8B', '#B8860B', '#A9A9A9', '#006400',
  '#BDB76B', '#8B008B', '#556B2F', '#FF8C00', '#9932CC', '#8B0000', '#E9967A', '#8FBC8F', '#483D8B', '#2F4F4F'
]

const CustomPieChart: React.FC<CustomPieChartProps> = ({ dashboardData }) => {
  const onPieEnter = () => {
  }

  return (
    <PieChart width={500} height={400} onMouseEnter={onPieEnter}>
      <Pie
        data={dashboardData.clientInStock}
        cx={100}
        cy={200}
        innerRadius={80}
        outerRadius={100}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="quantity"
        nameKey="client"
        isAnimationActive={true}
      >
        {dashboardData.clientInStock.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend layout="vertical" align="right" verticalAlign="middle" wrapperStyle={{ fontSize: 8 }} />
    </PieChart>
  )
}

export default CustomPieChart
