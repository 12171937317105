import { httpClient } from '../../config/httpClient'
import { StockFormType } from 'types/productControl/StockFormType'

export const GetDashboardDatas = async () => {
  return await httpClient.get('/product-control/get-dashboard-datas')
}

export const GetTagProductList = async (search: string) => {
  return await httpClient.get(`/product-control/tag-product-list?search=${search}`)
}

export const createStock = async (dadosForm: StockFormType) => {
  return await httpClient.post('/product-control', dadosForm)
}

export const getStockAll = async () => {
  return await httpClient.get('/product-control')
}

export const getStockId = async (id: string) => {
  return await httpClient.get(`/product-control/${id}`)
}

export const updateStock = async (dadosForm: StockFormType, id: string) => {
  return await httpClient.put(`/product-control/${id}`, dadosForm)
}

export const deleteStock = async (id: string) => {
  return await httpClient.delete(`/product-control/${id}`)
}

export const printTag = async (tag: string) => {
  return await httpClient.post('/product-control/send-print', tag)
}
