import React, { useCallback } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Button, IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import { useNavigate, useParams } from 'react-router-dom'
import { Edit, Delete, QrCode } from '@mui/icons-material'
import Input from '@mui/material/Input'
import SearchIcon from '@mui/icons-material/Search'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import DialogDelete from './components/DialogDelete'
import { deleteStock } from 'services/productControl/ProductControlService'
import { toast } from 'react-toastify'

const StockProductView: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = React.useState(false)
  const handleNavigate = useCallback(async (path: string) => {
    try {
      navigate(path)
    } catch (error) {
      console.error('Navigation error:', error)
    }
  }, [navigate])

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nome', width: 150 },
    { field: 'category', headerName: 'Código', width: 100 },
    { field: 'quantity', headerName: 'Cliente', type: 'number', width: 80 },
    { field: 'price', headerName: 'Data fabricacao', type: 'number', width: 110 },
    { field: 'supplier', headerName: 'Rua', width: 100 },
    { field: 'location', headerName: 'Lado', width: 100 },
    { field: 'status', headerName: 'estacao', width: 100 },
    { field: 'dateAdded', headerName: 'prateleira', type: 'date', width: 90 },
    { field: 'position', headerName: 'Posicao', type: 'number', width: 90 }
  ]

  const rows = [
    { id: 1, name: 'Product 1', category: 'Category 1', quantity: 100, price: 10.0, supplier: 'Supplier 1', location: 'Location 1', status: 'Available', dateAdded: '2023-01-01', position: 1 },
    { id: 2, name: 'Product 2', category: 'Category 2', quantity: 200, price: 20.0, supplier: 'Supplier 2', location: 'Location 2', status: 'Out of Stock', dateAdded: '2023-02-01', position: 2 }
    // Add more rows as needed
  ]

    const deletesotckconfirm = useCallback(async (id: string) => {
      try {
        deleteStock(id)
        toast.success('Estoque deletado com sucesso!')
      } catch (error) {
        console.error('Error:', error)
      } finally {
        handleNavigate('/home/stock-list')
      }
    }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
        <IconButton
          size='small'
          style={{ backgroundColor: '#4060E6', borderRadius: '0', color: '#ffffff' }}
          onClick={async () => handleNavigate('/home/stock-create')}
        >
          <AddIcon />
        </IconButton>
        <div style={{ display: 'flex', gap: '10px' }}>
          <IconButton
            size='small'
            style={{ backgroundColor: '#4060E6', borderRadius: '0', color: '#ffffff' }}
            onClick={async () => handleNavigate(`/home/stock-edit/${id}`)}
          >
            < Edit />
          </IconButton>
          <IconButton
            size='small'
            style={{ backgroundColor: '#4060E6', borderRadius: '0', color: '#ffffff' }}
            onClick={() => setOpenDialog(true)}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
        <Input id="my-input"
          placeholder="Search"
          endAdornment={<SearchIcon />}
         />
       <Button
       variant='outlined'
       endIcon={<QrCode />}>IMPRIMIR QR CODE DA ESTAÇÃO</Button>
      </div>
      <div>
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
        </Box>
      </div>
      <DialogDelete open={openDialog} onClose={() => { setOpenDialog(false) }} onDelete={() => { deletesotckconfirm(String(id)) }} itemId={String(id)} />
    </Box>
  )
}

export default React.memo(StockProductView)
