import React from 'react'
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  TextareaAutosize,
  Grid,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup
} from '@mui/material'
import AddStationModal from './components/AddSationModal'
import StationCard from './components/StationCard'
import { updateStock, getStockId } from '../../services/productControl/ProductControlService'
import { StockConfig } from 'types/productControl/StockFormType'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

const steps = ['Informaçoes Gerais', 'Configuraçao do estoque', 'Confirmação']

export default function StockCreateForm() {
  const { id } = useParams() as any
  const [loading, setLoading] = React.useState(true)
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const [station, setStations] = React.useState<any[]>([])
  const handleSaveStation = (workstation: any) => {
    setStations([...station, workstation])
  }

  const [stockDataCofig, setStockDataCofig] = React.useState({
    stockName: '',
    responsible: '',
    address: '',
    uniqueCode: '',
    streetsNumber: '',
    stockConfig: [
    ] as StockConfig[]
  })

  React.useEffect(() => {
    if (station.length > 0) {
      const newStockConfig = [...stockDataCofig.stockConfig]
      const lastStation = station[station.length - 1]

      if (!newStockConfig[lastStation.streetIndex]) {
        console.error(`Erro: O índice da rua (${lastStation.streetIndex}) não existe no stockConfig.`)
        return
      }

      if (!newStockConfig[lastStation.streetIndex].streetName) {
        newStockConfig[lastStation.streetIndex].streetName = `Rua ${Number(lastStation.streetIndex) + 1}`
      }

      const streetIndex = lastStation.streetIndex
      const sideIndex = Number(lastStation.side) - 1

      if (streetIndex >= 0 && streetIndex < newStockConfig.length && sideIndex >= 0) {
        const sides = newStockConfig[streetIndex].sides
        if (!sides[sideIndex]) {
          console.error(`Erro: O lado (${Number(sideIndex) + 1}) não existe para a rua ${Number(streetIndex) + 1}.`)
          return
        }

        sides[sideIndex].stations = [...sides[sideIndex].stations, lastStation]

        setStockDataCofig((prev) => ({
          ...prev,
          stockConfig: newStockConfig
        }))
      } else {
        console.error(
          `Erro: Não foi possível adicionar estação. Verifique se a rua (${streetIndex}) e lado (${sideIndex + 1}) existem no stockConfig.`
        )
      }
    }
  }, [station])

  React.useEffect(() => {
    const getStockConfigData = () => {
      getStockId(id)
        .then((response) => {
          const { data } = response
          const transformedData = {
            stockName: data.stockName,
            responsible: data.responsible,
            address: data.address,
            uniqueCode: data.uniqueCode,
            streetsNumber: data.streetsNumber,
            stockConfig: data.stockConfig.map((street) => ({
              streetName: street.streetName, // Ajuste no nome da rua
              sides: street.sides.map((side) => ({
                side: side.side,
                stations: side.stations.map((station) => ({
                  side: station.side,
                  name: station.name.replace('233', 'A'), // Ajuste no nome da estação
                  streetName: street.streetName, // Mantém o streetName como o nome da rua
                  streetIndex: station.streetIndex,
                  qtdShelves: station.qtdShelves,
                  shelves: station.shelves.map((shelf) => ({
                    positions: shelf.positions
                  }))
                }))
              }))
            }))
          }
          setStockDataCofig(transformedData) // Atualiza o estado com os dados transformados
        })
        .catch((error) => {
          toast.error(error.response.data.message)
          if (Array.isArray(error.response.data.message)) {
            error.response.data.message.forEach((msg: string) => {
              toast.error(msg)
            })
          } else {
            toast.error(error.response.data.message)
          }
        }).finally(() => {
          setLoading(false)
        }
        )
    }
    getStockConfigData()
  }
    , [])

  const handleDeleteStation = (streetIndex: number, sideIndex: number, cardIndex: number) => {
    console.log(`Deletar estação no índice: rua ${streetIndex}, lado ${sideIndex + 1}, card ${cardIndex}`)
    const newStockConfig = [...stockDataCofig.stockConfig]
    const sides = newStockConfig[streetIndex].sides

    if (sides && sides[sideIndex]) {
      sides[sideIndex].stations = sides[sideIndex].stations.filter((_, index) => index !== cardIndex)

      setStockDataCofig((prev) => ({
        ...prev,
        stockConfig: newStockConfig
      }))
    } else {
      console.error(`Erro: O lado (${sideIndex + 1}) não existe para a rua ${streetIndex + 1}.`)
    }
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setStockDataCofig({
      stockName: '',
      responsible: '',
      address: '',
      uniqueCode: '',
      streetsNumber: '',
      stockConfig: []
    })
  }

  const handlePushRoute = () => {
    window.location.href = '/home/stock-list'
  }

  const handleFinish = () => {
    updateStock(stockDataCofig, id)
      .then(() => {
        toast.success('Estoque criado com sucesso!')
        handleReset()
        handleNext()
      })
      .catch((error) => {
        toast.error(error.response.data.message)
        setActiveStep(0)
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((msg: string) => {
            toast.error(msg)
          })
        } else {
          toast.error(error.response.data.message)
        }
      })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, stepIndex) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (isStepSkipped(stepIndex)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {activeStep === steps.length
        ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              Configuracao de estoque completada com sucesso!
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handlePushRoute}>ok</Button>
            </Box>
          </React.Fragment>
        )
        : (
          <React.Fragment>
            {loading && <Typography sx={{ mt: 2, mb: 1 }}>Carregando...</Typography>}
            {activeStep === 0 && !loading && <Typography sx={{ mt: 2, mb: 1 }}>
              <Box
                component="form"
                autoComplete='off'>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Nome estoque"
                      defaultValue={stockDataCofig.stockName}
                      variant='standard'
                      onChange={(e) => setStockDataCofig({ ...stockDataCofig, stockName: e.target.value })}

                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Responsável"
                      defaultValue={stockDataCofig.responsible}
                      variant='standard'
                      onChange={(e) => setStockDataCofig({ ...stockDataCofig, responsible: e.target.value })}

                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Endereço"
                      defaultValue={stockDataCofig.address}
                      variant='standard'
                      onChange={(e) => setStockDataCofig({ ...stockDataCofig, address: e.target.value })}

                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Codigo único"
                      defaultValue={stockDataCofig.uniqueCode}
                      placeholder='Ex: 123456'
                      variant='standard'
                      onChange={(e) => setStockDataCofig({ ...stockDataCofig, uniqueCode: e.target.value })}

                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="obersevações"
                      style={{ width: '100%', borderRadius: '5px', padding: '5px' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Typography>}
            {activeStep === 1 && !loading && <Typography sx={{ mt: 2, mb: 1 }}>
              <Box
                component="form"
                autoComplete='off'>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="Numero de ruas"
                      defaultValue={stockDataCofig.streetsNumber}
                      variant='standard'
                      onChange={(e) => {
                        let streetsNumber = e.target.value
                        let streetsNumberInt = Number(streetsNumber)
                        if (streetsNumberInt > 10) {
                          streetsNumberInt = 10
                          streetsNumber = '10'
                        }
                        setStockDataCofig((prev) => {
                          const newStockConfig = prev.stockConfig.slice(0, Math.min(10, streetsNumberInt))
                          while (newStockConfig.length < streetsNumberInt) {
                            newStockConfig.push({
                              streetName: `Rua ${newStockConfig.length + 1}`,
                              sides: [{ side: 'Left', stations: [] }]
                            })
                          }
                          return {
                            ...prev,
                            streetsNumber,
                            stockConfig: newStockConfig
                          }
                        })
                      }}
                    />
                  </Grid>
                  {Array.from({ length: Number(stockDataCofig.streetsNumber) }).map((_, streetIndex) => {
                    if (!stockDataCofig.stockConfig[streetIndex]) {
                      setStockDataCofig((prev) => ({
                        ...prev,
                        stockConfig: [
                          ...prev.stockConfig,
                          {
                            streetName: `Rua ${streetIndex + 1}`,
                            sides: [{ side: 'Left', stations: [] }]
                          }
                        ]
                      }))
                    }

                    return (
                      <Grid item xs={12} marginBottom={6} key={streetIndex}>
                        <FormControl>
                          <FormLabel style={{ fontSize: '1.3rem', color: '#4060E6', fontWeight: 'bold' }}>
                            Rua {streetIndex + 1}
                          </FormLabel>
                          <FormLabel>Lados</FormLabel>
                          <RadioGroup
                            row
                            value={stockDataCofig.stockConfig[streetIndex]?.sides?.length === 2 ? 'Right' : 'Left'}
                            onChange={(event) => {
                              const sidesCount = event.target.value === 'Right' ? 2 : 1
                              setStockDataCofig((prev) => {
                                const newConfig = [...prev.stockConfig]
                                newConfig[streetIndex].sides = Array.from({ length: sidesCount }, (_, sideIndex) => ({
                                  side: sideIndex === 0 ? 'Left' : 'Right',
                                  stations: []
                                }))
                                return { ...prev, stockConfig: newConfig }
                              })
                            }}
                          >
                            <FormControlLabel value="Left" control={<Radio />} label="1" />
                            <FormControlLabel value="Right" control={<Radio />} label="2" />
                          </RadioGroup>
                        </FormControl>
                        <Grid style={{ marginTop: '1rem' }}>
                          <Typography variant="h6">Lado A</Typography>
                          <Grid container spacing={2}>
                            {stockDataCofig.stockConfig[streetIndex]?.sides[0]?.stations.map((station, stationIndex) => (
                              <Grid item xs={4} key={stationIndex}>
                                <StationCard stations={station} cardIndex={stationIndex} streetIndex={streetIndex} onDelete={handleDeleteStation} />
                              </Grid>
                            ))}
                          </Grid>
                          <AddStationModal
                            streetIndex={streetIndex}
                            streetName={`Rua ${streetIndex + 1}`}
                            onSave={handleSaveStation}
                            side={1}
                          />
                        </Grid>

                        {stockDataCofig.stockConfig[streetIndex]?.sides.length === 2 && (
                          <Grid style={{ marginTop: '1rem' }}>
                            <Typography variant="h6">Lado B</Typography>
                            <Grid container spacing={2}>
                              {stockDataCofig.stockConfig[streetIndex]?.sides[1]?.stations.map((station, stationIndex) => (
                                <Grid item xs={4} key={stationIndex}>
                                  <StationCard stations={station} cardIndex={stationIndex} streetIndex={streetIndex} onDelete={handleDeleteStation} />
                                </Grid>
                              ))}
                            </Grid>
                            <AddStationModal
                              streetIndex={streetIndex}
                              streetName={`Rua ${streetIndex + 1}`}
                              onSave={handleSaveStation}
                              side={2}
                            />
                          </Grid>
                        )}
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            </Typography>}
            {activeStep === 2 && !loading && <Typography sx={{ mt: 2, mb: 1 }}>
              <Box
                component="form"
                autoComplete='off'>
                <Grid container spacing={2} marginTop={4}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="body2">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>Nome do Estoque:</p>
                        <p style={{ fontWeight: '400', fontSize: '1.2rem' }}>{stockDataCofig.stockName}</p>
                      </div>
                    </Typography>
                    <Typography variant="body2">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>Endereço:</p>
                        <p style={{ fontWeight: '400', fontSize: '1.2rem' }}>{stockDataCofig.address}</p>
                      </div>
                    </Typography>
                    <Typography variant="body2">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>Responsável:</p>
                        <p style={{ fontWeight: '400', fontSize: '1.2rem' }}>{stockDataCofig.responsible}</p>
                      </div>
                    </Typography>
                    <Typography variant="body2">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>Código:</p>
                        <p style={{ fontWeight: '400', fontSize: '1.2rem' }}>{stockDataCofig.uniqueCode}</p>
                      </div>
                    </Typography>
                  </div>
                  {stockDataCofig.stockConfig.map((street, streetIndex) => (
                    <Grid item xs={12} marginBottom={6} key={streetIndex}>
                      <Typography variant="h6">Rua {streetIndex + 1}</Typography>
                      {street.sides.map((side, sideIndex) => (
                        <Grid item xs={12} marginBottom={6} key={sideIndex}>
                          <Typography variant="h6">Lado {side.side}</Typography>
                          <Grid container spacing={2}>
                            {side.stations.map((station, stationIndex) => (
                              <Grid item xs={4} key={stationIndex}>
                                <StationCard stations={station} cardIndex={stationIndex} streetIndex={streetIndex} onDelete={handleDeleteStation} />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Typography>}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Anterior
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}>
                {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
              </Button>
            </Box>
          </React.Fragment>
        )}
    </Box>
  )
}
