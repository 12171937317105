import React from 'react'
import './Card.css'

interface CardProps {
  title: string
  side: string
  shelves: number
  positions: number
  inUseShelves: number
  inUsePositions: number
  capacityPercentage: number
  clicked?: () => void
}

const Card: React.FC<CardProps> = ({
  title,
  side,
  shelves,
  positions,
  inUseShelves,
  inUsePositions,
  capacityPercentage,
  clicked
}) => {
  return (
    <div className={`card ${side === 'Left' ? 'card_A' : 'card_B'}`} onClick={ clicked }>
      <div className="card-header">
        <span>{title}</span>
        <span className="card-side">
          Lado {side === 'Left' ? 'A' : side === 'Right' ? 'B' : side}
        </span>
      </div>
      <div className="card-details">
        <div>
          <span>Prateleiras: {shelves}</span>
          <br />
          <span>Em uso: {inUseShelves}</span>
        </div>
        <div>
          <span>Posições: {positions}</span>
          <br />
          <span>Em uso: {inUsePositions}</span>
        </div>
      </div>
      <div className="card-capacity">
        <div
          className="capacity-bar"
          style={{ width: `${capacityPercentage}%` }}
        >
          <span className="capacity-text">
            Capacidade: {capacityPercentage}%
          </span>
        </div>
      </div>
    </div>
  )
}

export default Card
