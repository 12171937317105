import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'

interface DialogDeleteProps {
  open: boolean
  onClose: () => void
  onDelete: (id: string) => void
  itemId: string
}

const DialogDelete: React.FC<DialogDeleteProps> = ({ open, onClose, onDelete, itemId }) => {
  const handleDelete = () => {
    onDelete(itemId)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Aviso</DialogTitle>
      <DialogContent>
        <DialogContentText>
          O estoque selecionado será deletado permanentemente. Deseja continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancelar
        </Button>
        <Button onClick={handleDelete} color="primary" variant="contained" disableElevation>
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogDelete
