import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'

interface CardProps {
  cardIndex: number
  streetIndex: number
  stations: {
    side: number
    name: string
    streetName: number
    qtdShelves: number
    shelves: Array<{ positions: number }>
  }
  onDelete: (streetIndex: number, sideIndex: number, cardIndex: number) => void
}

const StationCard: React.FC<CardProps> = ({ cardIndex, streetIndex, stations, onDelete }) => {
  return (
    <Card
      style={{
        borderLeft: `15px solid ${stations.side === 1 ? '#5483E8' : '#36BE4C'}`,
        borderRight: `15px solid ${stations.side === 1 ? '#5483E8' : '#36BE4C'}`
      }}
    >
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <Typography variant="h5" component="div">
            {stations.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <span onClick={() => onDelete(streetIndex, stations.side - 1, cardIndex)}>
              <DeleteOutline color="info" style={{ cursor: 'pointer' }} />
            </span>
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body1" component="div">
            Prateleiras: {stations.qtdShelves}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Posições: {stations.shelves.reduce((acc, shelf) => acc + shelf.positions, 0)}
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}

export default StationCard
