import React, { useCallback, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Grid, IconButton, Tab, Tabs, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useNavigate, useParams } from 'react-router-dom'
import { getStockId, deleteStock } from 'services/productControl/ProductControlService'
import { Edit, Delete } from '@mui/icons-material'
import DialogDelete from './components/DialogDelete'
import { toast } from 'react-toastify'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { StockConfig } from 'types/productControl/StockFormType'
import CardStockCapacity from './components/CardStockCapacity'

const StockView: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = React.useState(false)
  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handlePushRoute = () => {
    window.location.href = '/home/stock-list'
  }
  const deletesotckconfirm = useCallback(async (id: string) => {
    try {
      deleteStock(id)
      toast.success('Estoque deletado com sucesso!')
    } catch (error) {
      console.error('Error:', error)
    } finally {
      handlePushRoute()
    }
  }, [])

  React.useEffect(() => {
    const getStockConfigData = () => {
      getStockId(String(id))
        .then((response) => {
          const { data } = response
          const transformedData = {
            stockName: data.stockName,
            responsible: data.responsible,
            address: data.address,
            uniqueCode: data.uniqueCode,
            streetsNumber: data.streetsNumber,
            stockConfig: data.stockConfig.map((street) => ({
              streetName: street.streetName, // Ajuste no nome da rua
              sides: street.sides.map((side) => ({
                side: side.side,
                stations: side.stations.map((station) => ({
                  side: station.side,
                  name: station.name.replace('233', 'A'), // Ajuste no nome da estação
                  streetName: street.streetName, // Mantém o streetName como o nome da rua
                  streetIndex: station.streetIndex,
                  qtdShelves: station.qtdShelves,
                  shelves: station.shelves.map((shelf) => ({
                    positions: shelf.positions
                  }))
                }))
              }))
            }))
          }
          setStockDataCofig(transformedData) // Atualiza o estado com os dados transformados
        })
        .catch((error) => {
          toast.error(error.response.data.message)
          if (Array.isArray(error.response.data.message)) {
            error.response.data.message.forEach((msg: string) => {
              toast.error(msg)
            })
          } else {
            toast.error(error.response.data.message)
          }
        }).finally(() => {
          /*   setLoading(false) */
        }
        )
    }
    getStockConfigData()
  }
    , [])

  const handleNavigate = useCallback(async (path: string) => {
    try {
      navigate(path)
    } catch (error) {
      console.error('Navigation error:', error)
    }
  }, [navigate])

  const columns: GridColDef[] = [
    { field: 'address', headerName: 'Endereço', width: 150 },
    { field: 'responsible', headerName: 'Responsável', width: 150 },
    { field: 'stockName', headerName: 'Nome do Estoque', width: 150 },
    { field: 'streetsNumber', headerName: 'Número de Ruas', width: 150 },
    { field: 'uniqueCode', headerName: 'Código Único', width: 150 }
  ]

  const [stockDataCofig, setStockDataCofig] = React.useState({
    stockName: '',
    responsible: '',
    address: '',
    uniqueCode: '',
    streetsNumber: '',
    stockConfig: [
    ] as StockConfig[]
  })

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton
          size='small'
          style={{ backgroundColor: '#4060E6', borderRadius: '0', color: '#ffffff' }}
          onClick={async () => handleNavigate('/home/stock-create')}
        >
          <AddIcon />
        </IconButton>
        <div style={{ display: 'flex', gap: '10px' }}>
          <IconButton
            size='small'
            style={{ backgroundColor: '#4060E6', borderRadius: '0', color: '#ffffff' }}
            onClick={async () => handleNavigate(`/home/stock-edit/${id}`)}
          >
            < Edit />
          </IconButton>
          <IconButton
            size='small'
            style={{ backgroundColor: '#4060E6', borderRadius: '0', color: '#ffffff' }}
            onClick={() => setOpenDialog(true)}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
      <div>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="stock tabs">
          <Tab label="Produto" />
          <Tab label="Ocupacão do Estoque" />
        </Tabs>
        {tabValue === 0 && (
          <Typography component="div">
            <DataGrid columns={columns} pageSize={5} rowsPerPageOptions={[5]} autoHeight rows={[]} />
          </Typography>
        )}
        {tabValue === 1 && (
          <Typography component="div">
            <Box
              component="form"
              autoComplete='off'>
              <Grid container spacing={2} marginTop={4}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Typography color={'#0044b0'} variant="h6">Nome do Estoque: {stockDataCofig.stockName}</Typography>
                </div>
                {stockDataCofig.stockConfig.map((street, streetIndex) => (
                  <Grid item xs={12} marginBottom={6} key={streetIndex}>
                    <Typography variant="h6">Rua {streetIndex + 1}</Typography>
                    {street.sides.map((side, sideIndex) => (
                      <Grid item xs={12} marginBottom={6} key={sideIndex}>
                        <Grid container spacing={2}>
                          {side.stations.map((station, stationIndex) => (
                            <Grid item xs={4} key={stationIndex} style={{ display: 'flex', justifyContent: 'center' }}>
                              <CardStockCapacity
                                  clicked={async () => handleNavigate(`/home/stock-product-view/${id}`)}
                                title={side.stations[stationIndex].name}
                                side={side.side}
                                shelves={side.stations[stationIndex].qtdShelves}
                                positions={side.stations[stationIndex].shelves.reduce((acc, shelf) => acc + shelf.positions, 0)}
                                inUseShelves={2}
                                inUsePositions={5}
                                capacityPercentage={70} />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Typography>
        )}
      </div>
      <DialogDelete open={openDialog} onClose={() => { setOpenDialog(false) }} onDelete={() => { deletesotckconfirm(String(id)) }} itemId={String(id)} />
    </Box>
  )
}

export default React.memo(StockView)
